import { useState, useEffect } from 'react';

const PERMISSION_SYSTEM_ADMIN = 'ApplicationSystemAdministrator';
const PERMISSION_BASIC_ADMIN = 'ApplicationBasicAdministrator';

export const useRolesAndPermissions = () => {
    const [rolesAndPermissions, setRolesAndPermissions] = useState(null);

    const updateRolesAndPermissions = () => {
        const storedData = localStorage.getItem('user-roles-and-permissions');
        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
                setRolesAndPermissions(parsedData);
            } catch (error) {
                console.error('Failed to parse roles and permissions from localStorage:', error);
            }
        } else {
            setRolesAndPermissions(null);
        }
    };

    useEffect(() => {
        updateRolesAndPermissions();

        const handleStorageChange = (event) => {
            if (event.key === 'user-roles-and-permissions') {
                updateRolesAndPermissions();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return rolesAndPermissions;
};

export const hasRole = (rolesAndPermissions, role) => {
    if (!rolesAndPermissions) {
        return false;
    }

    // TODO This needs to be investigated further, how the Coyote legacy app handles diff admin types
    //      Related to NG-1162 changes
    if (
        rolesAndPermissions.permissions.includes(PERMISSION_SYSTEM_ADMIN) ||
        rolesAndPermissions.permissions.includes(PERMISSION_BASIC_ADMIN)
    ) {
        return true;
    }

    return rolesAndPermissions.roles.includes(role);
};

export const hasPermission = (rolesAndPermissions, permission) => {
    if (!rolesAndPermissions) {
        return false;
    }

    // TODO This needs to be investigated further, how the Coyote legacy app handles diff admin types
    //      Related to NG-1162 changes
    if (
        rolesAndPermissions.permissions.includes(PERMISSION_SYSTEM_ADMIN) ||
        rolesAndPermissions.permissions.includes(PERMISSION_BASIC_ADMIN)
    ) {
        return true;
    }

    return rolesAndPermissions.permissions.includes(permission);
};
