import React from "react";
import StyledButton from "../../components/common/StyledButton"
import { CustomTabs, CustomTab } from "../../components/common/StyledTabs"
import AddIcon from '@mui/icons-material/Add';
import { literals } from "../../enums/literalCodes";
import "./dashboard.scss"

const DashboardControls = ({ selectedTabId, tabs, handleUpdateDashboard, isNewWidgetAdded }) => {

    const handleAddNewWidget = () => {
        handleUpdateDashboard({ addingNewWidget: true });
    };

    function handleTabChange(event, newValue) {

        if (newValue === literals.ADD_NEW_DASHBOARD_TAB) {
            alert('Add new tab flow goes here')
        } else {
            handleUpdateDashboard({ selectedTabId: newValue })
        }
    }

    return (
        <div className="dashboard-container">
            <div className="tabs-container">
                <CustomTabs
                    value={selectedTabId}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="Dashboard tabs"
                    selected={isNewWidgetAdded}
                >
                    {tabs.map(tab => {
                        return <CustomTab
                            key={tab.id}
                            aria-label={tab.title}
                            value={tab.id}
                            label={tab.title}
                        />
                    })}
                </CustomTabs>
            </div>
            {!(selectedTabId === 4 || selectedTabId === 5 || !selectedTabId) && (
                <div className="add-new-widget-button">
                    <StyledButton
                        onClick={handleAddNewWidget}
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        {literals.ADD_WIDGET}
                    </StyledButton>
                </div>
            )}
        </div>
    )
}
export default DashboardControls;