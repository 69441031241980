import React, { useState, useEffect, Suspense } from 'react';
import DashboardControls from './DashboardControls.jsx';
import NewWidgetSelector from '../NewWidgetSelector/index.jsx';
import './dashboard.scss';
import { useQuery } from '@apollo/client';
import { GET_ACTIVE_DASHBOARD } from './GraphQL/Queries';
import dashboardService from './GraphQL/Services.js';

//widgets:
import RecentClientsMatter from '../TimeEntry/RecentClientMatter/RecentClientsMatters';
import TimeEntryGrid from '../TimeEntry/TimeEntryGrid';
import AddTimeEntry from '../TimeEntry/AddTimeEntry/AddTimeEntry';
import AddExpenseEntry from '../Expense/AddExpenseEntry/AddExpenseEntry.jsx';
import ExpenseEntryDataGrid from '../Expense/ExpenseEntryDataGrid/ExpenseEntryDataGrid.jsx';
import FeeCalendar from '../TimeEntry/FeeCalendar/FeeCalendar.jsx';

import ApolloContextProvider from '../../GraphQL/ApolloContextProvider.js';
import { AutocompleteProvider } from '../../components/common/AutocompleteProvider.js';

import { literals } from '../../enums/literalCodes';
import { useSelector } from 'react-redux';
import jsUtils from '../../utils/jsUtils.js';
import layouts from './widgetsLayout.js';
import { Responsive, WidthProvider } from 'react-grid-layout';
import LTBInputWindow from '../LTB/LTBEntry/LTBInputWindow.jsx';

import { AddTimeEntryProvider } from './useAddTimeEntryContext';
import { DashboardStateSchema } from '../../validationSchemas/index.js';
import { hasRole, useRolesAndPermissions } from '../../utils/useRolesAndPermissions.js';

const LazyMatterInquiry = React.lazy(() =>
  import(/* webpackPrefetch: true */ '@Surepoint/MatterInquiry')
);

const MatterInquiry = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyMatterInquiry />
    </Suspense>
  );
};

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Dashboard = ({ tabURL }) => {
  const attorneyName = useSelector(
    (state) => state.userState.attorneyFirstName
  );
  const initialState = {
    id: 321,
    title: `${attorneyName ? attorneyName + "'s" : 'My'} Dashboard`,
    description: 'Add a description',
    model: {
      selectedTabId: 1,
      addingNewWidget: false,
      tabs: [
        {
          id: 1,
          title: literals.TIME_ENTRY,
          widgets: [],
          isWidgetAllowed: true,
        },
        // { id: 3, title: literals.EXPENSE, widgets: [], isWidgetAllowed: true },
        {
          id: 4,
          title: literals.LTB_DOCKETING,
          widgets: [],
          isWidgetAllowed: false,
        },
        {
          id: 5,
          title: literals.CLIENT_INQUIRY,
          widgets: [],
          isWidgetAllowed: false,
        },
      ],
    },
  };
  const [dashboardData, setDashboardData] = useState(initialState);
  const [dashboardId, setDashboardId] = useState(null);
  const rolesAndPermissions = useRolesAndPermissions();
  const hasTimeEntryReadPermission = hasRole(rolesAndPermissions, 'ActivityFeeRead');
  const hasTimeEntryWritePermission = hasRole(rolesAndPermissions, 'ActivityFeeWrite');
  const { data } = useQuery(GET_ACTIVE_DASHBOARD);
  const widgetComponents = {
    [literals.TIME_ENTRY_GRID]: {
      widget: TimeEntryGrid,
      uri: '/time-management/graphql',
    },
    [literals.TIME_ENTRY]: {
      widget: AddTimeEntry,
      uri: '/time-management/graphql',
    },
    [literals.RECENT_CLIENT]: {
      widget: RecentClientsMatter,
      uri: '/main/graphql',
    },
    [literals.EXPENSE_ENTRY]: {
      widget: AddExpenseEntry,
      uri: '/expense-management/graphql',
    },
    [literals.EXPENSE_ENTRY_GRID]: {
      widget: ExpenseEntryDataGrid,
      uri: '/expense-management/graphql',
    },
    [literals.LTB_DOCKETING]: {
      widget: LTBInputWindow,
      type: literals.LTB_DOCKETING,
      uri: '/time-management/graphql',
    },
    [literals.FEE_CALENDAR]: {
      widget: FeeCalendar,
      type: literals.FEE_CALENDAR,
      uri: '/time-management/graphql',
    },
    [literals.CLIENT_INQUIRY]: {
      widget: MatterInquiry,
      type: literals.CLIENT_INQUIRY,
    },
  };

  const isDashboardObjectStructureValid = async (_data) => {
    let response = await DashboardStateSchema.isValid(_data);
    return response;
  };

  useEffect(() => {
    if (data) {
      let { getActiveDashboard } = data;
      let isGettingFirstDashboard =
        typeof getActiveDashboard.model.tabs === 'undefined';

      if (!isGettingFirstDashboard) {
        let updatedDashboard = addMissingTabs(getActiveDashboard);
        if (!isDashboardObjectStructureValid(updatedDashboard)) {
          updatedDashboard = initialState;
        }
        setDashboardData(updatedDashboard);
      }
      setDashboardId(getActiveDashboard.dashboardId);
    }
  }, [data]);

  const addMissingTabs = (dashboardResponse) => {
    let newDashboard = jsUtils.deepClone(dashboardResponse);

    return {
      ...initialState,
      model: {
        ...initialState.model,
        selectedTabId: newDashboard.model.selectedTabId,
        addingNewWidget: newDashboard.model.addingNewWidget,
        tabs: initialState.model.tabs.map((tab) => ({
          ...tab,
          widgets:
            newDashboard.model.tabs.find((t) => t.id === tab.id)
              ?.widgets || tab.widgets,
        })),
      },
    };
  };

  const currentTabTitle = dashboardData.model.tabs.find(
    (tab) => tab.id === dashboardData.model.selectedTabId
  )?.title;
  const currentTabWidgets =
    dashboardData.model.tabs.find(
      (tab) => tab.id === dashboardData.model.selectedTabId
    )?.widgets || [];

  useEffect(() => {
    // Reset timers when user come to application for first time
    jsUtils.resetTimerDto();
  }, []);

  const updateDashboardCall = (dto) => {
    dashboardService
      .updateDashboard(dto)
      .then((data) => {
        let { updateActiveDashboard } = data?.data;

        if (updateActiveDashboard) {
          //toast message.
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (dashboardId) {
      let dto = {
        title: dashboardData.title,
        description: dashboardData.description,
        model: JSON.stringify(dashboardData.model),
      };
      jsUtils.debounce(updateDashboardCall, 800, dto);
    }
  }, [dashboardData]);

  useEffect(() => {
    const newDashboardData = jsUtils.deepClone(dashboardData);
    const { model } = newDashboardData;
    const newCurrentTabWidgets = model.tabs.find(
      (tab) => tab.id === model.selectedTabId
    );

    if (!newCurrentTabWidgets?.isWidgetAllowed) {
      handleAddNewWidget(newCurrentTabWidgets?.title);
    }
  }, [dashboardData.model.selectedTabId]);

  useEffect(() => {
    if (tabURL) {
      handleUpdateDashboard({ selectedTabId: parseInt(tabURL) });
    }
  }, [tabURL]);

  function handleUpdateDashboard(updatedData) {
    setDashboardData({
      ...dashboardData,
      model: {
        ...dashboardData.model,
        ...updatedData,
      },
    });
  }

  /**
   * Handles adding a new widget to the dashboard.
   * @param {string} widgetType - The type of widget to add.
   */
  function handleAddNewWidget(widgetType) {
    if (!widgetType) {
      return;
    }
    const newDashboardData = jsUtils.deepClone(dashboardData);
    const { model } = newDashboardData;
    const newCurrentTabWidgets = model.tabs.find(
      (tab) => tab.id === model.selectedTabId
    )?.widgets;

    !newCurrentTabWidgets.find(
      (widget) => widget.type == widgetType
    ) &&
      newCurrentTabWidgets.push({
        // TODO: confirm how we should generate IDs on the client-side. Use epoch for now to gua
      type: widgetType,
      id: Date.now(),
      layout: getWidgetLayout(widgetType),
    });

  newDashboardData.model.addingNewWidget = false;
  setDashboardData(newDashboardData);
    //update dashboard model
  }

  const getWidgetLayout = (widgetType) => {
    return layouts.find((x) => x.i === widgetType);
  };

  function removeWidgetFromDashboard(widgetId) {
    const newDashboardData = jsUtils.deepClone(dashboardData);
    const { model } = newDashboardData;
    const newCurrentTabWidgets = model.tabs.find(
      (tab) => tab.id === model.selectedTabId
    )?.widgets;
    const indexToRemove = newCurrentTabWidgets.findIndex(
      (widget) => widget.id === widgetId
    );
    newCurrentTabWidgets.splice(indexToRemove, 1);
    setDashboardData(newDashboardData);
  }

  const debounceLayoutChange = (layout) => {
    jsUtils.debounce(handleLayoutChange, 800, layout);
  };
  const handleLayoutChange = (layout) => {
    const updatedTabs = dashboardData.model.tabs.map((tab) => {
      const updatedWidgets = tab.widgets.map((widget) => {
        const layoutToUpdate = layout.find(
          (layout) => layout.i === widget.layout.i
        );
        if (layoutToUpdate) {
          const widgetsInSameRow = layout.filter(
            (otherWidgetLayout) =>
              otherWidgetLayout.y === layoutToUpdate.y &&
              otherWidgetLayout.i !== layoutToUpdate.i
          );

          if (widgetsInSameRow.length > 0) {
            const rightmostWidget = widgetsInSameRow.reduce(
              (prev, current) =>
                prev.x + prev.w > current.x + current.w
                  ? prev
                  : current
            );

            const newWidgetWidth = layoutToUpdate.w;
            const newWidgetRightX = layoutToUpdate.x + newWidgetWidth;

            if (
              newWidgetRightX >
              rightmostWidget.x + rightmostWidget.w
            ) {
              layoutToUpdate.x =
                rightmostWidget.x + rightmostWidget.w;
              layoutToUpdate.y = rightmostWidget.y;
            }
          } else {
            layoutToUpdate.x = 0;
          }

          return {
            ...widget,
            layout: { ...layoutToUpdate },
          };
        }
        return widget;
      });

      return {
        ...tab,
        widgets: updatedWidgets,
      };
    });

    setDashboardData({
      ...dashboardData,
      model: {
        ...dashboardData.model,
        tabs: updatedTabs,
      },
    });
  };

  function isWidgetAllowed() {
    const selectedTab = filteredTabsListBasedOnPermission(dashboardData.model.tabs,hasTimeEntryReadPermission,hasTimeEntryWritePermission).find(
      (tab) => tab.id === dashboardData.model.selectedTabId
    );
    if (!selectedTab) {
      return true;
    }
    return selectedTab.isWidgetAllowed;
  }

  function getWidgets() {
    const selectedTab = filteredTabsListBasedOnPermission(dashboardData.model.tabs,hasTimeEntryReadPermission,hasTimeEntryWritePermission).find(
      (tab) => tab.id === dashboardData.model.selectedTabId
    );
    if (!selectedTab) {
      return null; // Handle error or return default value
    }

    const layout = selectedTab.widgets.map((widget) => widget.layout);

    //hack to make sure client matters is full width
    if (
      layout[0]?.i === literals.CLIENT_INQUIRY ||
      layout[0]?.i === literals.LTB_DOCKETING
    ) {
      layout[0].w = 12;
      layout[0].minW = 12;
    }

    return currentTabWidgets.map((widget) => {
      const WidgetComponent = widgetComponents[widget.type];
      if (
        WidgetComponent?.widget &&
        widget.type !== literals.FEE_CALENDAR
      ) {
        return (
          <ResponsiveReactGridLayout
            layouts={{ lg: layout }}
            breakpoints={{
              lg: 1200,
              md: 996,
              sm: 768,
              xs: 480,
              xxs: 12,
            }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
            rowHeight={30}
            onLayoutChange={debounceLayoutChange}
            margin={[17, 17]}
            draggableHandle=".allow_drag"
          >
            <span
              key={widget.type}
              style={
                [
                  literals.LTB_DOCKETING,
                  literals.CLIENT_INQUIRY,
                ].includes(WidgetComponent.type)
                  ? { width: '100%' }
                  : WidgetComponent.widget === AddTimeEntry && {
                      height: 20,
                    }
              }
            >
              <ApolloContextProvider uri={WidgetComponent.uri}>
                <AutocompleteProvider>
                  <WidgetComponent.widget
                    removeWidgetFromDashboard={
                      removeWidgetFromDashboard
                    }
                    widgetId={widget.id}
                  />
                </AutocompleteProvider>
              </ApolloContextProvider>
            </span>
          </ResponsiveReactGridLayout>
        );
      } else if (
        WidgetComponent?.widget &&
        widget.type == literals.FEE_CALENDAR
      ) {
        return (
          <div style={{ margin: '18px 18px 0' }}>
            <ApolloContextProvider uri={WidgetComponent.uri}>
              <AutocompleteProvider>
                <WidgetComponent.widget
                  removeWidgetFromDashboard={
                    removeWidgetFromDashboard
                  }
                  widgetId={widget.id}
                />
              </AutocompleteProvider>
            </ApolloContextProvider>
          </div>
        );
      }
    });
  }

  const filteredTabsListBasedOnPermission=(tabs)=>{
    if(hasTimeEntryReadPermission || hasTimeEntryWritePermission){
      return tabs;
    }
    else{
      return tabs.filter((tab)=>tab.id !== 1);
    }
  }

  const tabIdWithPermission=(selectedTabId,)=>{
    if(selectedTabId === 1 && !(hasTimeEntryReadPermission || hasTimeEntryWritePermission)){
      return null;
    }
    return selectedTabId;
  }

  return (
    <div>
      <div className="main-content-header">
        <DashboardControls
          handleUpdateDashboard={handleUpdateDashboard}
          tabs={filteredTabsListBasedOnPermission(dashboardData.model.tabs,hasTimeEntryReadPermission,hasTimeEntryWritePermission)}
          selectedTabId={tabIdWithPermission(dashboardData.model.selectedTabId,hasTimeEntryReadPermission,hasTimeEntryWritePermission)}
          isNewWidgetAdded={dashboardData.model.addingNewWidget}
        />
      </div>
      <div
        className={'content'}
        style={{
          backgroundColor: dashboardData.model.addingNewWidget
            ? '#EAE6FA'
            : '#eaf4ff',
        }}
      >
        <AddTimeEntryProvider>
          {dashboardData.model.addingNewWidget &&
          isWidgetAllowed() ? (
            <NewWidgetSelector
              handleAddNewWidget={handleAddNewWidget}
              currentTabTitle={currentTabTitle}
            />
          ) : (getWidgets())}
        </AddTimeEntryProvider>
      </div>
    </div>
  );
};

export default Dashboard;
